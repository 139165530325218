<template>
  <div class="container_box">
    <home v-if="active == 'home'"></home>
    <mine v-if="active == 'mine'"></mine>
    <van-tabbar v-model="active" active-color="#3780FF" inactive-color="#A2AEC2">
      <van-tabbar-item name="home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? require('../assets/img/nav_statistic_pre@2x.png') : require('../assets/img/nav_statistic_nor@2x.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="mine">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? require('../assets/img/nav_my_pre@2x.png') : require('../assets/img/nav_my_nor@2x.png')" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import home from './home';
import mine from './mine';
export default {
  components: {
    home,
    mine
  },
  data() {
    return {
      active: 'home',
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
  .container_box {
    min-height: 100%;
    background: #F8F8F8;
    padding-bottom: .98rem;
  }
  /deep/ .van-tabbar {
    height: .98rem;
  }
</style>
